"use client";

import { FC } from "react";
import { useMountedState } from "react-use";

import { Card } from "@Features/advantage";
import { ComponentPageHomeCardAdvantage } from "@Shared/api";
import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { AnimationFadeIn } from "@Shared/ui";
import { getImageSrcFromApiData, twcx } from "@Shared/utils";

export type ListProps = ElProps<"div"> & {
    cards: ComponentPageHomeCardAdvantage[];
};

export const List: FC<ListProps> = ({ className, cards, ...restProps }) => {
    const isMounted = useMountedState();
    const { isSm } = useScreens();

    return (
        <div className={twcx("grid gap-3", "sm:grid-cols-2", "lg:gap-4")} {...restProps}>
            {cards.map((card, index) => (
                <AnimationFadeIn key={index + card.title} delay={isMounted() && isSm ? (index % 2) * 150 : 0}>
                    <Card
                        image={getImageSrcFromApiData(card.image.data?.attributes)}
                        imageDevices={getImageSrcFromApiData(card.imageDevices?.data?.attributes)}
                        title={card.title}
                        text={card.description}
                    />
                </AnimationFadeIn>
            ))}
        </div>
    );
};
