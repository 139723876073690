import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { Image, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

export type CardProps = ElProps<"div"> & {
    image?: string;
    imageDevices?: string;
    title: string;
    text?: string;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
    ({ className, image, imageDevices, title, text, ...restProps }, ref) => {
        const imageCommonClassName = "absolute left-0 top-0 size-full object-cover";
        const imageCommonProps = {
            alt: title,
            fill: true,
            sizes: { default: "85vw", md: "45vw" },
        };

        return (
            <div
                ref={ref}
                className={twcx(
                    "relative flex w-full flex-col gap-2 overflow-hidden rounded-xl bg-bg-blue p-3 pb-6 text-black",
                    "lg:rounded-[20px] lg:p-6",
                    className
                )}
                {...restProps}
            >
                <div
                    className={twcx(
                        "relative mb-2 h-0 overflow-hidden rounded-lg bg-black pt-[67.5%]",
                        "before:absolute before:left-1/2 before:top-1/2 before:h-[150%] before:w-[149%] before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full before:bg-gradient-card-advantage before:opacity-80",
                        "lg:mb-5 lg:rounded-2xl lg:pt-[49.5%]",
                        "lg:before:h-[169.5%] lg:before:w-[122.5%]"
                    )}
                >
                    {image && (
                        <Image
                            className={twcx(imageCommonClassName, {
                                "max-lg:hidden": imageDevices,
                            })}
                            src={image}
                            {...imageCommonProps}
                        />
                    )}

                    {imageDevices && (
                        <Image
                            className={twcx(imageCommonClassName, "lg:hidden")}
                            src={imageDevices}
                            {...imageCommonProps}
                        />
                    )}
                </div>

                <Text as="h3" variant="h4" weight={600}>
                    {title}
                </Text>

                {text && (
                    <Text as="p" weight={500} color="gray-02">
                        {text}
                    </Text>
                )}
            </div>
        );
    }
);

Card.displayName = "Card";
