"use client";

import { FC, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import { useScreens } from "@Shared/hooks";
import { Image, ImageProps } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import imageChainGlass from "./images/chain-glass.webp";

export const ImageChain: FC<ImageProps> = props => {
    const refComponent = useRef<HTMLImageElement>(null);

    const { width } = useWindowSize();
    const { isLg } = useScreens();

    useGSAP(
        () => {
            if (!refComponent.current) {
                return;
            }

            gsap.from(refComponent.current, {
                ease: "none",
                y: innerHeight / 1.25,
                scrollTrigger: {
                    trigger: refComponent.current.parentElement,
                    start: `bottom-=${isLg ? 300 : 100} bottom`,
                    end: `+=${innerHeight / 1.25}`,
                    scrub: 0.5,
                },
            });
        },
        {
            revertOnUpdate: true,
            dependencies: [width, isLg],
        }
    );

    return (
        <Image
            ref={refComponent}
            className={twcx(
                "pointer-events-none absolute bottom-[-75px] right-[-76px] -z-10 w-[233px] select-none",
                "lg:bottom-[-244px] lg:right-[-27px] lg:w-[392px]"
            )}
            src={imageChainGlass}
            alt="Chain glass"
            width={392}
            height={508}
            sizes={{ default: "233px", lg: "392px" }}
            {...props}
        />
    );
};
