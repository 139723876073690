"use client";

import { forwardRef, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import { ComponentPageHomeSectionAbout } from "@Shared/api";
import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

import ImageCursor from "./images/cursor.svg";
import styles from "./styles.module.scss";

export type CursorsProps = ElProps<"div"> & {
    list?: ComponentPageHomeSectionAbout["cursors"];
};

export const Cursors = forwardRef<HTMLDivElement, CursorsProps>(({ className, list, ...restProps }, ref) => {
    const refComponent = useRef<HTMLDivElement | null>(null);
    const refCursors = useRef<HTMLDivElement[]>([]);

    const { width } = useWindowSize();
    const { isSm } = useScreens();

    useGSAP(
        () => {
            if (!refComponent.current || !refCursors.current.length) {
                return;
            }

            const $section = refComponent.current.closest(".home-section-about");
            const $sectionContainer = $section?.querySelector(".home-section-about__container");
            const $sectionBefore = $section?.previousElementSibling;

            if (!$section || !$sectionContainer) {
                return;
            }

            const additionalOffset = isSm ? 150 : 75;
            const animationDistance = innerHeight / 1.5;
            const containerProps = {
                width: refComponent.current.offsetWidth,
                height: refComponent.current.offsetHeight,
                halfWidth: refComponent.current.offsetWidth / 2,
                halfHeight: refComponent.current.offsetHeight / 2,
            };

            const sectionContainerTop = $sectionBefore
                ? $section.getBoundingClientRect().top - $sectionBefore.getBoundingClientRect().bottom
                : 0;

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: $section,
                    start: `top top+=${sectionContainerTop}`,
                    end: `+=${animationDistance}`,
                    scrub: 0.5,
                },
            });

            gsap.set($section, { clearProps: "minHeight" });

            gsap.set($section, {
                minHeight: `${$section.clientHeight + animationDistance}px`,
            });

            gsap.set($sectionContainer, {
                position: "sticky",
                top: sectionContainerTop,
            });

            gsap.set(refCursors.current, { clearProps: "transform" });

            refCursors.current.forEach($el => {
                let x = 0;
                let y = 0;

                if ($el.offsetLeft < containerProps.halfWidth) {
                    x = -$el.offsetLeft - $el.offsetWidth - additionalOffset;
                } else {
                    x = containerProps.width - $el.offsetLeft + additionalOffset;
                }

                if ($el.offsetTop < containerProps.halfHeight) {
                    y = -$el.offsetTop - $el.offsetHeight - additionalOffset;
                } else {
                    y = containerProps.height - $el.offsetTop + additionalOffset;
                }

                tl.from(
                    $el,
                    {
                        ease: "none",
                        x,
                        y,
                    },
                    0
                );
            });
        },
        {
            scope: refComponent,
            revertOnUpdate: true,
            dependencies: [width, isSm],
        }
    );

    if (!list?.length) {
        return;
    }

    return (
        <div
            ref={el => {
                if (typeof ref === "function") {
                    ref(el);
                } else if (ref) {
                    ref.current = el;
                }

                refComponent.current = el;
            }}
            className={twcx("pointer-events-none absolute inset-0 select-none", className)}
            {...restProps}
        >
            {list.map((cursor, index) => (
                <div
                    key={index + cursor}
                    ref={el => {
                        if (el && !refCursors.current.includes(el)) {
                            refCursors.current.push(el);
                        }
                    }}
                    className={twcx("absolute left-1/2 top-1/2 will-change-transform", styles.cursor)}
                >
                    <ImageCursor className={twcx("w-[37px] text-blue-light", "lg:w-[41px]")} />

                    <div
                        className={twcx(
                            "absolute left-full top-full -ml-3 -mt-1 whitespace-nowrap bg-blue-light px-2 py-1 text-center font-secondary text-[18px] leading-[22px] text-black shadow-[0_3px_7px_0_rgba(0,0,0,0.12)]",
                            "lg:-ml-2 lg:-mt-1.5 lg:text-[20px] lg:leading-[24px]"
                        )}
                    >
                        {cursor}
                    </div>
                </div>
            ))}
        </div>
    );
});
