import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { AnimationNumber, Badge, Image, Link, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type CardPropsData = {
    key: string;
    value: string;
};

export type CardProps = ElProps<"div"> & {
    link?: string;
    linkBlank?: boolean;
    image?: string;
    imageDevices?: string;
    badges?: string[];
    name: string;
    description?: string;
    data?: CardPropsData[];
    dataPosition?: "left" | "right";
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
    (
        {
            className,
            link,
            linkBlank,
            image,
            imageDevices,
            badges,
            name,
            description,
            data,
            dataPosition = "right",
            ...restProps
        },
        ref
    ) => {
        const isBig = !!data?.length;
        const imageCommonClassName = "absolute left-0 top-0 size-full object-cover";
        const imageCommonProps = {
            alt: name,
            fill: true,
            sizes: { default: "90vw", sm: isBig ? "95vw" : "50vw", lg: isBig ? "50vw" : "30vw" },
        };

        return (
            <div
                ref={ref}
                className={twcx(
                    "relative flex w-full flex-col gap-6",
                    "sm:gap-4",
                    "lg:flex-row lg:gap-10",
                    {
                        "lg:flex-row-reverse": isBig && dataPosition === "left",
                    },
                    className
                )}
                {...restProps}
            >
                <div
                    className={twcx("relative flex w-full flex-col gap-2", {
                        group: link,
                        "lg:w-[50.7%] lg:shrink-0": isBig,
                    })}
                >
                    <div
                        className={twcx("relative mb-1 h-0 overflow-hidden rounded-xl pt-[72.5%]", "lg:mb-2", {
                            "bg-black": !image && !imageDevices,
                            "sm:rounded-[20px] sm:pt-[61%] lg:pt-[63.75%]": isBig,
                            "sm:rounded-2xl sm:pt-[121.5%] lg:rounded-[20px] lg:pt-[115.75%]": !isBig,
                        })}
                    >
                        {image && (
                            <Image
                                className={twcx(imageCommonClassName, {
                                    "max-sm:hidden": imageDevices,
                                    "lg:transition-transform lg:group-hover:scale-110": link,
                                })}
                                src={image}
                                {...imageCommonProps}
                            />
                        )}

                        {imageDevices && (
                            <Image
                                className={twcx(imageCommonClassName, "sm:hidden")}
                                src={imageDevices}
                                {...imageCommonProps}
                            />
                        )}

                        {!!badges?.length && (
                            <div className={twcx("absolute left-2 top-2 flex flex-wrap gap-2", "sm:left-4 sm:top-4")}>
                                {badges.map(badge => (
                                    <Badge key={badge}>{badge}</Badge>
                                ))}
                            </div>
                        )}
                    </div>

                    <Text as="h3" variant="h5" weight={700} color="black">
                        {name}
                    </Text>

                    {description && (
                        <Text as="p" variant="p2" color="gray-02">
                            {description}
                        </Text>
                    )}

                    {link && (
                        <Link className="absolute inset-0" href={link} target={linkBlank ? "_blank" : undefined} />
                    )}
                </div>

                {isBig && (
                    <div
                        className={twcx(
                            "relative flex flex-wrap gap-3 pt-4",
                            "max-lg:before:absolute max-lg:before:left-0 max-lg:before:top-0 max-lg:before:h-7 max-lg:before:w-full max-lg:before:rounded-xl max-lg:before:border-t max-lg:before:border-t-gray-04",
                            "max-lg:after:absolute max-lg:after:left-1/2 max-lg:after:top-0 max-lg:after:-ml-1.5 max-lg:after:h-6 max-lg:after:w-px max-lg:after:-translate-x-1/2 max-lg:after:bg-gray-04",
                            "sm:gap-12 sm:pt-6",
                            "sm:max-lg:after:ml-0 sm:max-lg:after:h-[60px]",
                            "lg:grow lg:flex-col lg:gap-16 lg:pt-0"
                        )}
                    >
                        {data.map(({ key, value }, index) => (
                            <div
                                key={value + index}
                                className={twcx(
                                    "flex min-w-[40%] flex-1 flex-col gap-2",
                                    "lg:relative lg:min-w-0 lg:flex-none lg:flex-row lg:items-center lg:gap-0 lg:pb-2 lg:pt-6",
                                    "lg:before:absolute lg:before:left-0 lg:before:top-0 lg:before:h-7 lg:before:w-full lg:before:rounded-xl lg:before:border-t lg:before:border-t-gray-04",
                                    "lg:after:absolute lg:after:left-1/2 lg:after:top-0 lg:after:ml-2 lg:after:h-[60px] lg:after:w-px lg:after:-translate-x-1/2 lg:after:bg-gray-04"
                                )}
                            >
                                <AnimationNumber>
                                    <Text className="lg:w-1/2 lg:shrink-0" variant="h2Lg" weight={600} color="black">
                                        {key}
                                    </Text>
                                </AnimationNumber>

                                <Text
                                    className="lg:-mt-3 lg:w-1/2 lg:shrink-0 lg:pl-12 lg:pr-10"
                                    variant="p2"
                                    color="gray-02"
                                >
                                    {value}
                                </Text>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

Card.displayName = "Card";
