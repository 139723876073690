"use client";

import { FC, useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

import { ComponentPageHomeSectionMovie } from "@Shared/api";
import { useScreens } from "@Shared/hooks";
import { ElProps } from "@Shared/types";
import { ContainerSection, Image } from "@Shared/ui";
import { getImageSrcFromApiData, twcx } from "@Shared/utils";

export type SectionMovieProps = ElProps<"section"> & ComponentPageHomeSectionMovie;

export const SectionMovie: FC<SectionMovieProps> = ({
    className,
    video,
    videoDevices,
    image,
    imageDevices,
    ...restProps
}) => {
    const refVideo = useRef<HTMLVideoElement>(null);

    const [src, setSrc] = useState<string>("");
    const [poster, setPoster] = useState<string | undefined>();

    const intersection = useIntersection(refVideo, { threshold: 0 });
    const { isLg } = useScreens();

    useEffect(() => {
        if (!video?.file.data && !videoDevices?.file.data) {
            return;
        }

        const videoSrc = getImageSrcFromApiData(video?.file.data?.attributes);
        const videoDevicesSrc = getImageSrcFromApiData(videoDevices?.file.data?.attributes);

        if ((isLg || !videoDevices) && videoSrc && src !== videoSrc) {
            setSrc(videoSrc);
            setPoster(getImageSrcFromApiData(video?.poster.data?.attributes));
        } else if (!isLg && videoDevicesSrc && src !== videoDevicesSrc) {
            setSrc(videoDevicesSrc);
            setPoster(getImageSrcFromApiData(videoDevices?.poster.data?.attributes));
        }
    }, [isLg]);

    useEffect(() => {
        if (refVideo.current && src) {
            refVideo.current.load();
        }
    }, [src]);

    useEffect(() => {
        if (intersection && intersection.isIntersecting && refVideo.current?.paused) {
            refVideo.current?.play();
        } else if (intersection && !intersection.isIntersecting && !refVideo.current?.paused) {
            refVideo.current?.pause();
        }
    }, [src, intersection]);

    if (!video?.file.data && !image?.data) {
        return;
    }

    const commonClassName = "size-full object-cover";

    return (
        <ContainerSection className={twcx("h-svh-section bg-transparent !px-0", className)} as="section" {...restProps}>
            {src && (
                <video
                    ref={refVideo}
                    className={twcx(commonClassName, {
                        "max-lg:hidden": !videoDevices?.file && imageDevices?.data,
                        "lg:hidden": !video?.file && videoDevices?.file && image?.data,
                    })}
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="metadata"
                    poster={poster}
                >
                    <source src={src} />
                </video>
            )}

            {!video?.file && image?.data && (
                <Image
                    className={twcx(commonClassName, {
                        "max-lg:hidden": videoDevices?.file || imageDevices?.data,
                    })}
                    src={getImageSrcFromApiData(image.data.attributes)}
                    role="presentation"
                    fill
                />
            )}

            {!videoDevices?.file && imageDevices?.data && (
                <Image
                    className={twcx(commonClassName, "lg:hidden")}
                    src={getImageSrcFromApiData(imageDevices.data.attributes)}
                    role="presentation"
                    fill
                />
            )}
        </ContainerSection>
    );
};
