"use client";

import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

import { useScreens } from "./tailwind";

const setVhVar = () => {
    document.documentElement.style.setProperty("--vh", `${innerHeight / 100}px`);

    document.documentElement.style.setProperty(
        "--vmax",
        `${(innerWidth > innerHeight ? innerWidth : innerHeight) / 100}px`
    );
};

export const useVUnits = () => {
    const [lastWidth, setLastWidth] = useState(0);

    const { width, height } = useWindowSize();
    const { isLg } = useScreens();

    useEffect(() => {
        if (!isLg && width === lastWidth) {
            return;
        }

        setVhVar();
        setLastWidth(width);
    }, [width, height, isLg]);
};
