import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { Image, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

type CardPropsClassNames = {
    text?: string;
};

export type CardProps = ElProps<"div"> & {
    classNames?: CardPropsClassNames;
    text: string;
    image?: string;
    name: string;
    caption?: string;
};

export const Card = forwardRef<HTMLDivElement, CardProps>(
    ({ className, classNames, text, image, name, caption, ...restProps }, ref) => {
        return (
            <div
                ref={ref}
                className={twcx("relative flex w-full flex-col gap-3", "lg:gap-4", className)}
                {...restProps}
            >
                <Text
                    className={twcx(
                        "rounded-xl rounded-bl-sm bg-white p-3",
                        "lg:rounded-[20px] lg:rounded-bl-[4px] lg:p-6",
                        classNames?.text
                    )}
                    weight={500}
                    color="gray-01"
                >
                    {text}
                </Text>

                <div className={twcx("flex items-start gap-2", "lg:items-center lg:gap-4")}>
                    <div
                        className={twcx(
                            "relative flex size-11 shrink-0 items-center justify-center overflow-hidden rounded-full",
                            "lg:size-[68px]",
                            {
                                "bg-white": !image,
                            }
                        )}
                    >
                        {image && (
                            <Image
                                className="size-full object-cover"
                                src={image}
                                alt={name}
                                fill
                                sizes={{ default: "44px", lg: "68px" }}
                            />
                        )}
                    </div>

                    <div className="flex flex-col gap-1">
                        <Text weight={600} color="white">
                            {name}
                        </Text>

                        {caption && (
                            <Text variant="p2" weight={500} color="gray-04">
                                {caption}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

Card.displayName = "Card";
