"use client";

import { FC, useEffect, useRef } from "react";
import { useWindowSize } from "react-use";

import { Card } from "@Entities/review";
import { CollectionReview, ResponseData } from "@Shared/api";
import { ElProps } from "@Shared/types";
import { AnimationFadeIn, Slider } from "@Shared/ui";
import { getImageSrcFromApiData, twcx } from "@Shared/utils";

const CARD_TEXT_CLASSNAME = "review-card-text";

export type ListProps = ElProps<"div"> & {
    reviews: ResponseData<CollectionReview>[];
};

export const List: FC<ListProps> = ({ className, reviews, ...restProps }) => {
    const refComponent = useRef<HTMLDivElement>(null);

    const { width } = useWindowSize();

    useEffect(() => {
        if (!refComponent.current) {
            return;
        }

        const $cardTexts = refComponent.current.querySelectorAll(`.${CARD_TEXT_CLASSNAME}`);

        $cardTexts.forEach(el => {
            el.removeAttribute("style");
        });

        const maxHeightOfText = Array.from($cardTexts).reduce((acc, el) => Math.max(acc, el.clientHeight), 0);

        $cardTexts.forEach(el => {
            el.setAttribute("style", `min-height: ${maxHeightOfText}px`);
        });
    }, [width]);

    return (
        <div
            ref={refComponent}
            className={twcx(
                "-mx-3 grow",
                "md:-mx-4",
                "lg:mx-0 lg:-mr-11 lg:overflow-hidden lg:rounded-tl-[20px]",
                className
            )}
            {...restProps}
        >
            <Slider
                classNames={{
                    slide: twcx(
                        "!flex !h-auto",
                        "max-sm:!w-[82.5vw]",
                        "max-md:!w-[55vw]",
                        "max-lg:!w-[44vw]",
                        "lg:!w-[30vw]"
                    ),
                }}
                slidesPerView="auto"
                spaceBetween={12}
                slidesOffsetBefore={12}
                slidesOffsetAfter={12}
                navigation={{
                    prevEl: "#reviews-slider-button-prev",
                    nextEl: "#reviews-slider-button-next",
                }}
                breakpoints={{
                    768: {
                        slidesOffsetBefore: 16,
                        slidesOffsetAfter: 16,
                    },
                    1025: {
                        spaceBetween: 16,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 44,
                    },
                }}
                slides={reviews.map(({ attributes: review }, index) => (
                    <AnimationFadeIn key={index + review.name} delay={index * 150}>
                        <Card
                            classNames={{ text: CARD_TEXT_CLASSNAME }}
                            text={review.text}
                            image={getImageSrcFromApiData(review.image.data?.attributes)}
                            name={review.name}
                            caption={review.caption}
                        />
                    </AnimationFadeIn>
                ))}
            />
        </div>
    );
};
