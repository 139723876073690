"use client";

import { FC, useRef } from "react";
import { useWindowSize } from "react-use";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";

import { ComponentPageHomeSectionAbout } from "@Shared/api";
import { PAGES } from "@Shared/config";
import { ElProps } from "@Shared/types";
import { Button, ContainerSection, Text } from "@Shared/ui";
import { twcx } from "@Shared/utils";

import { Cursors } from "./Cursors";
import imageBgDevices from "./images/bg-d.jpg";
import imageBg from "./images/bg.jpg";
import { Lines } from "./Lines";

export type SectionAboutProps = ElProps<"section"> & ComponentPageHomeSectionAbout;

export const SectionAbout: FC<SectionAboutProps> = ({ className, title, buttonAboutText, cursors, ...restProps }) => {
    const refComponent = useRef<HTMLDivElement>(null);
    const refLines = useRef<HTMLDivElement>(null);
    const refCursors = useRef<HTMLDivElement>(null);
    const refTitle = useRef<HTMLHeadingElement>(null);
    const refButton = useRef<HTMLDivElement>(null);

    const { width } = useWindowSize();

    useGSAP(
        () => {
            if (!refComponent.current || !refLines.current || !refTitle.current) {
                return;
            }

            const tl = gsap
                .timeline({
                    scrollTrigger: {
                        trigger: refComponent.current,
                        start: "top center",
                    },
                })
                .from(refComponent.current, {
                    ease: "none",
                    duration: 1,
                    clipPath: "inset(50% 0)",
                })
                .from(
                    refLines.current,
                    {
                        ease: "none",
                        duration: 0.75,
                        yPercent: -100,
                        scale: 1.25,
                    },
                    "-=25%"
                )
                .from(
                    refTitle.current,
                    {
                        ease: "none",
                        y: 75,
                        opacity: 0,
                    },
                    "-=50%"
                );

            if (refButton.current) {
                tl.from(
                    refButton.current,
                    {
                        ease: "none",
                        duration: 0.25,
                        y: 15,
                        opacity: 0,
                    },
                    "-=50%"
                );
            }

            if (refCursors.current) {
                tl.from(refCursors.current, {
                    ease: "none",
                    opacity: 0,
                });
            }
        },
        {
            scope: refComponent,
            revertOnUpdate: true,
            dependencies: [width],
        }
    );

    return (
        <section ref={refComponent} className="home-section-about scroll-m-t-header relative w-full" id="about">
            <ContainerSection
                className={twcx(
                    "home-section-about__container",
                    "min-h-svh-section flex flex-col items-center justify-center gap-4 py-48",
                    "lg:gap-8",
                    className
                )}
                bgImageCover={{ src: imageBg, srcDevices: imageBgDevices }}
                {...restProps}
            >
                <Lines ref={refLines} />
                <Cursors ref={refCursors} list={cursors} />

                <Text ref={refTitle} className="lg:max-w-[1140px]" as="h2" variant="h3" weight={600} align="center">
                    {title}
                </Text>

                {buttonAboutText && (
                    <div ref={refButton}>
                        <Button variant="underline" icon="angleRight" href={PAGES.about}>
                            {buttonAboutText}
                        </Button>
                    </div>
                )}
            </ContainerSection>
        </section>
    );
};
