import { forwardRef } from "react";

import { ElProps } from "@Shared/types";
import { twcx } from "@Shared/utils";

export type LinesProps = ElProps<"div">;

export const Lines = forwardRef<HTMLDivElement, LinesProps>(({ className, ...restProps }, ref) => {
    return (
        <div
            ref={ref}
            className={twcx(
                "pointer-events-none absolute inset-0 z-10 flex justify-between overflow-hidden px-[38px]",
                className
            )}
            {...restProps}
        >
            {Array(11)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={index}
                        className={twcx("h-full w-px shrink-0 bg-white opacity-[0.12]", {
                            "max-sm:hidden": index > 2,
                            "max-md:hidden": index > 4,
                            "max-lg:hidden": index > 6,
                        })}
                    />
                ))}
        </div>
    );
});
